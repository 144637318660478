import { DOMAIN_NAME } from "./main.js";
import { stateManager, startPreloader, stopPreloader, setDisabled, setEnabled } from "./form-manager.js";
import { selector } from "./selectors-module.js";

let debounceTimer;

/**
 * This function send request and generate options (based on JSON response) for autocomplete selectBox
 * @param {string} textInput - class/id of input (type = text) into which the text is written 
 * @param {string} selectInput - class/id of selectBox into which autocomplete result (options) is added
 * @param {string} fce - calling function name 
 * @param {function=} callback - optional callback function which will be executed after user pick the option
 * @param {string=} otherAttr - optional other attribute for calling function * 
 */
export function autocomplete(textInput, selectInput, fce, callback = false, otherAttr = '') {
    let input = document.querySelector(textInput);
    let select = document.querySelector(selectInput);
    input.addEventListener('input', () => {
        //clear existing debounce timer
        clearTimeout(debounceTimer);
        //set new debounce timer
        debounceTimer = setTimeout(() => {
            let text = input.value;
            if (text !== "") {
                isProposalType(select)
                fetchData(input, select, fce, callback, otherAttr, text);
            } else {
                select.classList.add('none');
            }
        }, 300);
    });
}

/** Check if autocomplete box is in the proposal section. 
 * If yes, submit btn is disabled when autocomplete is activated.
 * If result is picked then enable submit Btn  
 * @param {Object} select - selectBox into which autocomplete result (options) is added
 * @param (bool) result - set true if result is picked from selectbox options
 * */
export function isProposalType(select, result = false) {
    let proposalPart = document.querySelector('.proposal');
    let isAtcBoxInProposal = proposalPart.contains(select);
    let isResultPicked = result;
    if (isAtcBoxInProposal != false) {
        if (isResultPicked === true) {
            setEnabled(selector.submitBtn);
        } else {
            setDisabled(selector.submitBtn);
        }
    }
}

/**
 * Fetch the data from server, fill selectbox and add eventlisteners
 * @param {Object} input - text input into which the text is written 
 * @param {Object} select - selectBox into which autocomplete result (options) is added
 * @param {string} fce - calling function name
 * @param {function} callback - optional callback function which will be executed after user pick the option
 * @param {string=} otherAttr  - optional other attribute for calling function 
 * @param {string} text - value of textinput
 */
function fetchData(input, select, fce, callback, otherAttr, text) {
    startPreloader()
    fetch(DOMAIN_NAME + 'reklamaceRUIAN/' + fce + '?text=' + text + otherAttr)
        .then((response) => response.json())
        .then((data) => {
            stopPreloader()
            let optionOfSelectInput = [];
            if (data.length <= 0) {
                optionOfSelectInput += `<option value="not-found">Nenalezen žádný záznam</option>`;
                select.setAttribute("size", data.length);
                select.classList.remove("none");
                select.innerHTML = optionOfSelectInput;
            } else {
                for (let i = 0; i < data.length; i++) {
                    optionOfSelectInput += `<option value="${data[i].kod}">${data[i].nazev}</option>`;
                    select.setAttribute("size", data.length);
                    select.classList.remove("none");
                    select.innerHTML = optionOfSelectInput;
                }
            }
        })
        .then(() => {
            //reset obec input when user click outside of autocomplete select (not choose any option)
            document.addEventListener('click', (e) => {
                let isClickInside = select.contains(e.target);
                if (!isClickInside && !select.classList.contains('none') && select.classList.contains('obec_atc')) {
                    input.value = '';
                    select.classList.add('none');
                    isProposalType(select, true);
                    stateManager.setState('initialization');
                }
            });
            //reset ulice proposal input when user click outside of autocomplete select (not choose any option) / only for ad-du type
            if (stateManager.form_type === 'ad-du') {
                document.addEventListener('click', (e) => {
                    let isClickInside = select.contains(e.target);
                    if (!isClickInside && !select.classList.contains('none') && select.classList.contains('ulice_proposal_atc')) {
                        input.value = '';
                        isProposalType(select, true);
                        select.classList.add('none');
                    }
                });
            }
            //check if the element already has a listener. If not, add one.
            if (!select.getAttribute('listener')) {
                //detect if device is touchable
                function is_touchable_device() {
                    if ((navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
                        return true;
                    }
                }
                //eventlistener for click on option in selectBox
                if (is_touchable_device() === true) {
                    select.addEventListener("touchend", () => {
                        if (select.value !== 'not-found') {
                            resultPickHandler(input, select, callback);
                        }
                    }, select.setAttribute('listener', true));
                } else {
                    select.addEventListener("click", () => {
                        if (select.value !== 'not-found') {
                            resultPickHandler(input, select, callback);
                        }
                    }, select.setAttribute('listener', true));
                }
                //eventlistener for key events in textBox (choosing from selectBox)
                input.addEventListener("keydown", (e) => {
                    let s = select;
                    // for esc
                    if (e.keyCode === 27) {
                        s.classList.add('none');
                        return;
                    }
                    // for arrow down	
                    if (e.keyCode == 40) {
                        s.selectedIndex = s.selectedIndex >= 0 && s.selectedIndex < s.length - 1 ? s.selectedIndex + 1 : 0;
                        input.value = s[s.selectedIndex].innerHTML;
                        return;
                    }
                    // for arrow up	
                    else if (e.keyCode === 38) {
                        s.selectedIndex = s.selectedIndex > 0 ? s.selectedIndex - 1 : s.length - 1;
                        input.value = s[s.selectedIndex].innerHTML;
                        return;
                    }
                    // for enter
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        if (select.value !== 'not-found') {
                            resultPickHandler(input, s, callback);
                        }
                    }
                });
            }
        })
        .catch((error) => console.error('There was a problem with the request: ' + error));
}

/**
 * handler for picking options from generated combo box. This function add value of selected option to parent text input. 
 * Execute the callback function if specified.
 * @param {HTML Element} textInput - text input into which the text is written 
 * @param {HTML Element} selectInput - autocomplete selectBox form which is option picked
 * @param {function} callback - callback function which will be executed after user pick the option
 */
function resultPickHandler(textInput, selectInput, callback) {
    let select = selectInput;
    let text = select[select.selectedIndex].innerHTML;
    textInput.value = text.replace(/&amp;/g, '&');
    select.classList.add('none');
    isProposalType(select, true)
    if (callback === false) {
        return
    } else {
        callback(textInput.value, select[select.selectedIndex].value);
    }
}
