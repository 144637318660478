export const selector = {
    kodRuianInput: isElement('.kod_ruian'),
    notFoundBox: isElement('.notFound-desc'),
    moreResultsBox: isElement('.moreResults-desc'),
    noStreetBox: isElement('.noStreet'),
    noStreetForNumberBox: isElement('.noStreetForNumber'),
    fetchBtn: isElement('.fetchBtn'),
    submitBtn: isElement('#send'),
    obecTextInput: isElement('.obec'),
    obecKod: isElement('#obec_kod'),
    obecAutocompleteInput: isElement('.obec_atc'),
    obecProposalTextInput: isElement('.obec_proposal'),
    obecProposalAutocompleteInput: isElement('.obec_proposal_atc'),
    momc: isElement('.obec_obvod'),
    momcProposal: isElement('.obec_obvod_proposal'),
    typSo: isElement('.typ_so'),
    typSoProposal: isElement('.typ_so_proposal'),
    castObce: isElement('.cast_obce'),
    castObceProposal: isElement('.cast_obce_proposal'),
    ulice: isElement('.ulice'),
    uliceKod: isElement('#ulice_kod'),
    uliceAutocomplete: isElement('.ulice_atc'),
    uliceProposal: isElement('.ulice_proposal'),
    uliceProposalAutocomplete: isElement('.ulice_proposal_atc'),
    cisloPopisne: isElement('.cislo_popisne'),
    cisloPopisneProposal: isElement('.cislo_popisne_proposal'),
    cisloPopisnePuvodni: isElement('.cislo_popisne_puvodni'),
    cisloPopisneAutocomplete: isElement('.cislo_popisne_atc'),
    cisloDomovniTyp: isElement('.cislo_domovni_typ'),
    cisloDomovni: isElement('.cislo_domovni'),
    cisloOrientacni: isElement('.cislo_orientacni'),
    cisloOrientacniProposal: isElement('.cislo_orientacni_proposal'),
    idRuian: isElement('#id_ruian'),
    katUzemi: isElement('.kat_uzemi'),
    katUzemiProposal: isElement('.kat_uzemi_proposal'),
    parcelaId: isElement('.parcela_id'),
    parcelniCislo: isElement('.parcelni_cislo'),
    parcelniCisloProposal: isElement('.parcelni_cislo_proposal'),
    parcelniCisloAutocomplete: isElement('.parcelni_cislo_atc'),
    parcelniCisloAutocompleteProposal: isElement('.parcelni_cislo_proposal_atc'),
    typParcely1: isElement('.typ_parcely_1'),
    typParcely2: isElement('.typ_parcely_2'),
    typParcely1Proposal: isElement('.typ_parcely_1_proposal'),
    typParcely2Proposal: isElement('.typ_parcely_2_proposal'),
    zpusobVyuziti: isElement('.zpusob_vyuziti'),
    tea_vodovod: isElement('.tea_vodovod'),
    tea_podlazi: isElement('.tea_podlazi'),
    tea_plyn: isElement('.tea_plyn'),
    tea_vytah: isElement('.tea_vytah'),
    tea_dokonceno: isElement('.tea_dokonceno'),
    tea_konstrukce: isElement('.tea_konstrukce'),
    tea_obestaveno: isElement('.tea_obestaveno'),
    tea_zastaveno: isElement('.tea_zastaveno'),
    tea_byty: isElement('.tea_byty'),
    tea_podlahova: isElement('.tea_podlahova'),
    tea_kanalizace: isElement('.tea_kanalizace'),
    tea_vytapeni: isElement('.tea_vytapeni'),
    psc: isElement('.psc'),
    defb_x: isElement('.defb_x'),
    defb_y: isElement('.defb_y'),
    defb_x_hasici: isElement('.defb_x_hasici'),
    defb_y_hasici: isElement('.defb_y_hasici'),
    defb_x_zzs: isElement('.defb_x_zzs'),
    defb_y_zzs: isElement('.defb_y_zzs'),
    defb_x_Proposal: isElement('.defb_x_proposal'),
    defb_y_Proposal: isElement('.defb_y_proposal'),
    defb_x_hasici_Proposal: isElement('.defb_x_hasici_proposal'),
    defb_y_hasici_Proposal: isElement('.defb_y_hasici_proposal'),
    defb_x_zzs_Proposal: isElement('.defb_x_zzs_proposal'),
    defb_y_zzs_Proposal: isElement('.defb_y_zzs_proposal'),
    caraUliceProposal: isElement('.cara_ulice_proposal'),
    popisReklamace: isElement('.popis_proposal'),
    errorField: isElement('.error'),
    burger: isElement('.burger'),
    main_nav_links: isElement('.main-nav-links'),
    main_nav_links_li: isElements('.main-nav-links li'),
}

function isElement(element) {
    return document.querySelector(element);
}

function isElements(element) {
    return document.querySelectorAll(element);
}