import { building } from './main.js';

let map = {
    marQueryId: "",
    paramCount: 1,
    code: null,
    iskn: false,
    marushkaId: false,
    divWrapper: null,
    setCode(code) {
        this.code = code;
    },
    setDivWrapper(selector) {
        this.divWrapper = document.querySelector(selector);
    }
}

/**
 * Fetch the localization map from server (create <img> and set the src atribute, append it to wrapping div)
 * @param {string} type - type of view (e.g. 'so' for buliding)
 */
export function fetchMap(type) {
    setProps(type);
    const img = document.createElement("img");
    const vdp = document.createElement("a");

    img.src = "https://katastralnimapy.cuzk.cz/Handlers/vratlokalizacninahled.ashx?themeid=69&MarQueryID=" + map.marQueryId +
        "&MarWidth=360&MarHeight=340&MarQParamCount=" + map.paramCount +
        "&MarUid=90BA7CE9%20C2EDF424&MarQParam0=" + map.code +
        "&MarQParam1=";


    img.onload = function () {
        img.setAttribute('class', 'localization-map');
        vdp.setAttribute('class', 'vdp-link');
        vdp.setAttribute('target', '_blank');
        vdp.setAttribute('title', 'Odkaz do mapy');
        if (map.iskn === true) {
            vdp.setAttribute('href', 'http://sgi-nahlizenidokn.cuzk.cz/marushka/default.aspx?themeid=3&MarQueryID=' + map.marushkaId + '&MarQParamCount=1&MarPanelLegend=false&MarQParam0=' + map.code);
        } else {
            vdp.setAttribute('href', 'https://vdp.cuzk.cz/marushka/?ThemeID=1&InfoURL=http://vdp.cuzk.cz/vdp/ruian&MarUid=0D3E163B%206EAC62F2&MarUidi=0D3E163B%206EAC62F2&MarQueryID=' + type.toUpperCase() + '&MarQParamCount=1&MarPanelLegend=false&MarQParam0=' + map.code);
        }
        vdp.innerHTML = `<i class="fas fa-map-marked-alt"></i>`
        map.divWrapper.appendChild(img);
        map.divWrapper.appendChild(vdp);
    }
    img.onerror = function () {
        let mapError = document.createElement("p");
        mapError.innerText = 'Náhled prvku není k dispozici.';
        mapError.classList.add('map-error');
        map.divWrapper.appendChild(mapError);
    }


}

/**
 * set properties of map object
 * @param {type} type - type of view (e.g. 'so' for buliding)
 */
function setProps(type) {
    if (type === 'so') {
        map.marQueryId = "2B74A08D";
        map.paramCount = 2;
        map.setCode(building.final_data.forObject.kodruian);
        map.setDivWrapper('.map_so');
    }
    else if (type === 'ad') {
        map.marQueryId = "4D5ADD32";
        map.paramCount = 1;
        map.setCode(building.final_data.forObject.kodruian);
        map.setDivWrapper('.map_so');
    }
    else if (type === 'ul') {
        map.marQueryId = "69F6E545";
        map.paramCount = 1;
        map.setCode(building.final_data.forObject.kodruian);
        map.setDivWrapper('.map_so');
    }
    else if (type === 'bdb') {
        map.marQueryId = "950D0575";
        map.marushkaId = "81F2A805";
        map.paramCount = 1;
        map.iskn = true;
        map.setCode(building.final_data.forObject.kodruian);
        map.setDivWrapper('.map_so');
    }
    else if (type === 'pdb') {
        map.marQueryId = "26E1EED6";
        map.marushkaId = "2EDA9E08";
        map.paramCount = 1;
        map.iskn = true;
        map.setCode(building.final_data.forObject.kodruian);
        map.setDivWrapper('.map_so');
    }
}
