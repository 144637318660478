import { building } from "./main.js";
import { selector } from "./selectors-module.js";

export function initProposal(state) {
    console.log(state);
    console.log(building);
    const fillBox = (objectData, inputField) => {
        let defaultOption = `<option value>Vyberte ze seznamu</option>`;
        let data = objectData;
        let input = inputField;
        for (let i = 0; i < data.length; i++) {
            defaultOption += `<option value="${data[i].KOD}" data-cr="${data[i].CISELNA_RADA}">${data[i].NAZEV}</option>`;
            input.innerHTML = defaultOption;
        }
    }

    if (selector.castObceProposal) {
        fillBox(building.final_data.forObec.cast_obce, selector.castObceProposal);
        // pridano kvuli navrhu
            selector.castObceProposal.value = building.final_data.forObject.cast_obce_kod;
            selector.cisloPopisneProposal.value = building.final_data.forObject.cislo_popisne;
            selector.typSoProposal.value = building.final_data.forObject.typ_so;
            building.getTypSoProposal(selector.typSoProposal);
    }

    if (selector.katUzemiProposal) {
        fillBox(building.final_data.forObec.katuze, selector.katUzemiProposal);
    }
    
       
}