import { building } from "./main.js";
import { autocomplete, isProposalType } from "./autocomplete-module.js";
import { initProposal } from "./proposal-module.js";
import { selector } from "./selectors-module.js";
import { DOMAIN_NAME } from "./main.js";


/**
 * stateManager is an object which hold current state of form. 
 * Information about the current state is passed to formManager
 * (formManager manage bahavior of form)
 */
export let stateManager = {
    form_type: getTypeOfForm(),
    state: "initialization",

    setState(value) {
        this.state = value
        formManager(this.state)
    }
}

/**
 * manage bahavior of whole form (based of current state).
 * @param {string} state - current state of form
 */
export function formManager(state) {
    switch (state) {
        case 'initialization':
            if (selector.kodRuianInput) {
                resetToInitialState('.identification');
                setEnabled(selector.kodRuianInput);
                setEnabled(selector.obecTextInput);
                setDisabled(selector.fetchBtn);
            } else {
                resetToInitialState('.proposal');
                initiateProposalSpecificTypeOfForms();
            }
            building.resetAllValues();
            break;
        case 'by_ruian_code':
            resetToInitialState('.identification');
            setEnabled(selector.fetchBtn);
            setDisabled(selector.obecTextInput);
            selector.obecTextInput.value = '';
            setEnabled(selector.fetchBtn);
            break;
        case 'by_params':
            building.resetAllValues();
            if (stateManager.form_type === 'so-d' || stateManager.form_type === 'ad-d' || stateManager.form_type === 'ul-d') {
                resetToInitialState('.proposal');
                initiateProposalSpecificTypeOfForms();
            } else {
                resetToInitialState('.identification');
                setDisabled(selector.kodRuianInput);
            }
            break;
        case 'not_found':
            selector.notFoundBox.classList.remove('none');
            selector.notFoundBox.innerText = 'Objekt nenalezen!';
            selector.kodRuianInput.classList.add('notFound');
            break;
        case 'not_cislo_popisne':
            selector.notFoundBox.classList.remove('none');
            selector.notFoundBox.innerText = 'Reklamovat lze pouze stavební objekty s č.p. nebo č.ev.';
            selector.kodRuianInput.classList.add('notFound');
            break;
        case 'is_cislo_popisne':
            selector.notFoundBox.classList.remove('none');
            selector.notFoundBox.innerText = 'Reklamovat lze pouze stavební objekty bez č.p. s č.ev.';
            selector.kodRuianInput.classList.add('notFound');
            break;
        case 'no_street_delete':
            selector.notFoundBox.classList.remove('none');
            if (stateManager.form_type === 'ad-doc') {
                selector.notFoundBox.innerText = 'Adresní místo se nenachází v žádné ulici.';
            } else {
                selector.notFoundBox.innerText = 'V obci není uliční síť!';
            }
            selector.kodRuianInput.classList.add('notFound');
            break;
        case 'no_street':
            selector.noStreetBox.classList.remove('none');
            selector.noStreetBox.innerText = 'V obci není uliční síť!';
            selector.obecTextInput.classList.add('notFound');
            setDisabled(selector.submitBtn);
            break;
        case 'no_street_for_number':
            selector.noStreetForNumberBox.classList.remove('none');
            selector.noStreetForNumberBox.innerText = 'Toto číslo domovní se nenachází v žádné ulici. Doplnění orientačního čísla lze použít pouze pro adresy s uvedenou ulicí.';
            selector.cisloPopisne.classList.add('notFound');
            setDisabled(selector.submitBtn);
            break;
        case 'obec':
            if (selector.kodRuianInput) {
                setDisabled(selector.kodRuianInput);
            }
            if ((stateManager.form_type === 'ad-du' || (stateManager.form_type.startsWith("ul") && stateManager.form_type != 'ul-d') || stateManager.form_type === 'ad-zu') && building.init_data.ulice.length < 1) {
                stateManager.setState('no_street');
            } else if (stateManager.form_type.startsWith("ul") && stateManager.form_type != 'ul-d') {
                setEnabled(selector.ulice);
                selector.obecKod.value = building.obec;
                building.getUlice('.ulice', '.ulice_atc');
            } else if (stateManager.form_type === 'ul-d') {
                setEnabled(selector.uliceProposal);
                selector.obecKod.value = building.obec;
                // building.getUlice('.ulice', '.ulice_atc');
            } else if (stateManager.form_type === 'pdb') {
                setEnabled(selector.katUzemi);
                selector.obecKod.value = building.obec;
                building.getKatUzemi(selector.katUzemi);
            } else {
                setEnabled(selector.momc);
                building.getMomc(selector.momc);
            }
            // to deactivate typ_so option for so without cislo_popisne
            if (stateManager.form_type === 'so-dc' || stateManager.form_type === 'so-ta') {
                let options = document.querySelector('.typ_so').children;
                for (let i = 0; i < options.length; i++) {
                    if (options[i].value == 3) {
                        options[i].disabled = true;
                    }
                }
            }
            if (stateManager.form_type === 'so-td') {
                let options = document.querySelector('.typ_so').children;
//		console.log(options);
                for (let i = 0; i < options.length; i++) {
                    if (options[i].value == 3) {
                        options[i].selected = true;
                        stateManager.setState('typ_so_without_number');
                    } else {
                        options[i].disabled = true;
                    }
                }
            }
            break;
        case 'momc':
            if (stateManager.form_type.startsWith("so")) {
                building.getTypSo(selector.typSo);
                setEnabled(selector.typSo, selector.cisloPopisne, selector.cisloPopisneAutocomplete);
            } else {
                setEnabled(selector.castObce);
                building.getCastObce(selector.castObce);
            }
            break;
        case 'momc_without':
            setDisabled(selector.momc);
            selector.momc.innerText = "";
            if (stateManager.form_type.startsWith("so") || stateManager.form_type === 'obu') {
                setEnabled(selector.typSo);
                building.getTypSo(selector.typSo, selector.cisloPopisne, selector.cisloPopisneAutocomplete);
            } else {
                setEnabled(selector.castObce);
                building.getCastObce(selector.castObce);
            }
            break;
        case 'typ_so':
            building.getCastObce(selector.castObce);
            setEnabled(selector.castObce, selector.cisloPopisne, selector.cisloPopisneAutocomplete);
            setDisabled(selector.katUzemi);
            setDisabled(selector.typParcely1);
            setDisabled(selector.typParcely2);
            setDisabled(selector.parcelniCislo);

            if (selector.fetchBtn) {
                setDisabled(selector.fetchBtn);
            }
            selector.katUzemi.innerHTML = `<option value="">Vyberte ze seznamu</option>`;
            selector.parcelniCislo.value = "";
            if (selector.parcelaId) {
                selector.parcelaId.value = '';
            }
            uncheckBothTypParcely()
            if (stateManager.form_type === 'so-d') {
                setEnabled(selector.katUzemi, selector.typParcely1, selector.typParcely2, selector.parcelniCislo);
                building.getKatUzemi(selector.katUzemi);
            }
            break;
        case 'typ_so_without_number':
            setDisabled(selector.castObce);
            setDisabled(selector.cisloPopisne);
            if (selector.fetchBtn) {
                setDisabled(selector.fetchBtn);
            }
            selector.castObce.innerHTML = ''
            selector.cisloPopisne.value = '';
            if (selector.parcelaId) {
                selector.parcelaId.value = '';
            }
            setEnabled(selector.katUzemi);
            building.getKatUzemi(selector.katUzemi);
            break;
        case 'typ_so_proposal':
            console.log("here with cp");
            setEnabled(selector.castObceProposal);
            setEnabled(selector.cisloPopisneProposal);
            break;
        case 'typ_so_without_number_proposal':
            console.log("here without cp");
            setDisabled(selector.castObceProposal);
            setDisabled(selector.cisloPopisneProposal);
//            selector.castObceProposal.innerHTML = '';
//            selector.cisloPopisneProposal.value = '';
            break;
        case 'cast_obce':
            if (stateManager.form_type.startsWith('so') || stateManager.form_type === 'obu') {
                if (stateManager.form_type != 'so-d') {
                    building.getCisloPopisne();
                    setEnabled(selector.cisloPopisneAutocomplete);
                }
                setEnabled(selector.cisloPopisne);
            } else {
                if (stateManager.form_type === 'ad-d' && building.init_data.ulice.length > 0) {
                    setEnabled(selector.uliceProposal)
                }
                selector.cisloDomovniTyp.value = '';
                selector.cisloPopisne.value = null;
                if (selector.noStreetForNumberBox) {
                    selector.noStreetForNumberBox.classList.add('none');
                    selector.cisloPopisne.classList.remove('notFound');
                }
                selector.cisloPopisneAutocomplete.classList.add('none');
                if (selector.fetchBtn) {
                    setDisabled(selector.fetchBtn)
                }
                ;
                setDisabled(selector.cisloPopisne);
                setEnabled(selector.cisloDomovniTyp);
                building.getCisloDomovniTyp(selector.cisloDomovniTyp, selector.cisloPopisne, selector.cisloPopisneAutocomplete);
            }
            break;
        case 'ulice':
            if (stateManager.form_type.startsWith('ul') && stateManager.form_type != 'ul-d') {
                building.setKodRuian(building.ulice);
                stateManager.setState('params_completed');
            }
            if (stateManager.form_type === 'ad-d') {
                selector.uliceKod.value = building.ulice;
                selector.uliceProposal.addEventListener('input', () => {
                    if (selector.uliceProposal.value === '') {
                        setDisabled(selector.cisloOrientacniProposal);
                        building.setUlice(undefined);
                        selector.uliceKod.value = "";
                        selector.cisloOrientacniProposal.value = '';
                    }
                })
                setEnabled(selector.cisloOrientacniProposal);
            }
            if (stateManager.form_type === 'ad-du') {
                selector.uliceKod.value = building.ulice;
            }
            break;
        case 'cislo_domovni_typ':
            if (stateManager.form_type != 'ad-d') {
                setEnabled(selector.cisloPopisne);
                building.getCisloPopisne();
            } else {
                setEnabled(selector.cisloPopisne);
                building.getCisloPopisne();
                setEnabled(selector.psc);
            }
            break;
        case 'cislo_popisne':
            stateManager.setState('params_completed');
            break;
        case 'kat_uzemi':
            setDisabled(selector.typParcely1);
            setDisabled(selector.typParcely2);
            setDisabled(selector.parcelniCislo);
            selector.parcelniCislo.value = "";
            if (building.ciselna_rada === '2') {
                uncheckBothTypParcely()
                setEnabled(selector.typParcely1);
                setEnabled(selector.typParcely2);
                building.getTypParcely();
            } else {
                setEnabled(selector.parcelniCislo);
                if (stateManager.form_type === 'so-d') {
                    if (building.typ_parcely === 2) {
                        selector.typParcely2.checked = true;
                    }
                    if (building.typ_parcely === 1) {
                        selector.typParcely1.checked = true;
                    }
                }
                building.getParcelniCislo(building.typ_parcely, '.parcelni_cislo', '.parcelni_cislo_atc');
            }
            break;
        case 'typ_parcely':
            selector.parcelniCislo.value = '';
            if (selector.fetchBtn) {
                setDisabled(selector.fetchBtn);
            }
            setEnabled(selector.parcelniCislo);
            building.getParcelniCislo(building.typ_parcely, '.parcelni_cislo', '.parcelni_cislo_atc');
            break;
        case 'parcelni_cislo':
            if (stateManager.form_type === 'so-d') {
                //returning parcela id
                selector.parcelaId.value = building.kod_ruian;
                // building.getFinalDataByCode(building.kod_ruian);
            } else {
                stateManager.setState('params_completed');
            }
            break;
        case 'params_completed':
            setEnabled(selector.fetchBtn);
            break;

        case 'vice_cislo_popisne':
            selector.moreResultsBox.classList.remove('none');
            selector.moreResultsBox.innerText = 'Návrh na změnu technickoekonomických atributů jednotlivých vchodů lze uvést do popisu reklamace.';
            break;

        case 'final_data':
            selector.fetchBtn.classList.add('none');
            openProposalSection();
            initProposal(state);
            initiateProposalSpecificTypeOfForms();
            break;
        default:
    }
}

/**
 * Function for initialization of identification section of the form. 
 * Add listeners and handle toggle all necessary atributes between cases "insert 
 * by ruian code" or "by parameters"
 */
export function startIdentification() {
    //insert by ruian code
    if (selector.kodRuianInput) {
        selector.kodRuianInput.addEventListener('input', () => {
            if (selector.kodRuianInput.value === '') {
                if (selector.noStreetForNumberBox) {
                    selector.noStreetForNumberBox.classList.add('none');
                }
                if (selector.noStreetBox) {
                    selector.noStreetBox.innerText = '';
                }
                if (selector.notFoundBox) {
                    selector.notFoundBox.innerText = '';
                }
                selector.kodRuianInput.classList.remove('notFound');
                selector.obecTextInput.classList.remove('notFound');
                selector.obecTextInput.value = '';
                stateManager.setState('initialization');
            } else {
                stateManager.setState('by_ruian_code');
            }
        })
        selector.kodRuianInput.addEventListener('keydown', (e) => {
            let invalidChars = [
                "-",
                "+",
                "e",
            ];
            if (invalidChars.includes(e.key)) {
                e.preventDefault();
            }
        });
    }


    //insert by parameters
    if (selector.obecTextInput) {
        selector.obecAutocompleteInput.classList.add('none');
        autocomplete('.obec', '.obec_atc', 'getobec', building.getObec.bind(building));
        selector.obecTextInput.addEventListener('input', () => {
            if (selector.obecTextInput.value === '') {
                if (selector.noStreetBox) {
                    selector.noStreetBox.classList.add('none');
                }
                if (selector.noStreetForNumberBox) {
                    selector.noStreetForNumberBox.classList.add('none');
                    selector.cisloPopisne.classList.remove('notFound');
                }
                selector.obecTextInput.classList.remove('notFound');
                stateManager.setState('initialization');
            } else {
                stateManager.setState('by_params');
            }
        })
    }
    //fetch button for fetch object based on parameters
    if (selector.fetchBtn) {
        selector.fetchBtn.addEventListener('click', (e) => {
            e.preventDefault();
            let ruianErrMsg = document.querySelector('#frm-mainForm-mainForm-i_kod_ruian_msg');
            if (!ruianErrMsg.classList.contains('has-error-msg')) {
                if (stateManager.state === 'params_completed') {
                    selector.notFoundBox.classList.add('none');
                    selector.notFoundBox.innerText = '';
                    selector.kodRuianInput.classList.remove('notFound');
                    building.getFinalDataByCode(building.kod_ruian);
                } else {
                    selector.notFoundBox.classList.add('none');
                    selector.notFoundBox.innerText = '';
                    selector.kodRuianInput.classList.remove('notFound');
                    building.getFinalDataByCode(selector.kodRuianInput.value);
                }
            }
        }, selector.fetchBtn.setAttribute('listener', true));
    }
    //initiate type specific types of forms (proposal only etc.)
    if (stateManager.form_type === 'so-d' || stateManager.form_type === 'ad-d' || stateManager.form_type === 'ul-d'
            || stateManager.form_type === 'ad-du' || stateManager.form_type === 'ul-dnu' || stateManager.form_type === 'xmlruian'
            || stateManager.form_type === 'xmliskn' || stateManager.form_type === 'so-dt' || stateManager.form_type === 'so-dp'
            || stateManager.form_type === 'so-ta') {
        initiateProposalSpecificTypeOfForms();
    }

    if (selector.submitBtn) {
        //start preloader if submit btn is clicked and there is no validation errors on client side
        selector.submitBtn.addEventListener('click', () => {
            setTimeout(() => {
                let errors = document.querySelectorAll('.has-error-msg');
                if (errors.length === 0) {
                    startPreloader();
                }
            }, 300);
        });
        //stop preloader if there was an error returned from db and error snippet is redrawn
        naja.snippetHandler.addEventListener('afterUpdate', (event) => {
            if (event.detail.snippet.id === 'snippet-mainForm-error') {
                setTimeout(() => {
                    event.preventDefault();
                    stopPreloader();
                }, 300);
            }
        });
    }

}

/**
 * reset all values of all inputs (in part of form focused on identification) and set them as disabled.
 * for proper function add the (html) class "autocomplete" to the corresponding autocomplete select box. 
 * Add the class "noreset" to the fields which can NOT be reseted.
 * Add the class "to-default" to the selectbox which you want to provide with default option.
 */
export function resetToInitialState(wrapingElement) {
    let wrap = document.querySelector(wrapingElement);
    let selectInputs = wrap.querySelectorAll('select:not(.autocomplete):not(.noreset)');
    let textInputs = wrap.querySelectorAll('input:not(.noreset)');
    let toDefault = wrap.querySelectorAll('.to-default');
    let autocompleteBoxes = document.querySelectorAll('.autocomplete');
    let allInputs = Array.from(selectInputs).concat(Array.from(textInputs));

    if (selector.noStreetBox) {
        selector.noStreetBox.classList.add('none');
    }
    if (selector.obecTextInput) {
        selector.obecTextInput.classList.remove('notFound');
    }
    if (selector.fetchBtn) {
        setDisabled(selector.fetchBtn);
    }
    if (selector.typParcely1 || selector.typParcely2) {
        uncheckBothTypParcely();
        setDisabled(selector.typParcely1);
        setDisabled(selector.typParcely2);
    }
    autocompleteBoxes.forEach(selectBox => selectBox.classList.add('none'));
    toDefault.forEach(selectBox => selectBox.innerHTML = `<option value="">Vyberte ze seznamu</option>`);
    allInputs.forEach(input => {
        setDisabled(input);
        input.value = '';
    })
}

/**
 * Reset parameters of individual field
 * @param {string} textField - css selector (id/class) of text input
 * @param {string=} selectField - css selector (id/class) of select input
 */
export function resetField(textField, selectField) {
    setDisabled(textField);
    textField.value = null;
    if (selectField) {
        selectField.value = null;
        selectField.classList.add('none');
    }
}

/**
 * Facade for set element as disabled
 * @param {object} element - HTML element
 */
export function setDisabled(element) {
    element.setAttribute('disabled', '');
}

/**
 * Facade for remove attribute 'disabled' of element
 * @param {object} element - HTML element 
 */
export function setEnabled(element) {
    element.removeAttribute('disabled');
}
/**
 * Facade for add class 'lock' of element
 * @param {object} element - HTML element 
 */
export function setLocked(element) {
    element.classList.add("lock");
}

/**
 * get type of Form from url and set them as property of 'stateManager' object
 */
function getTypeOfForm() {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let type = url.searchParams.get("type");
    return type;
}

/**
 * open the proposal section of form after the final data od identification are fetched
 */
function openProposalSection() {
    let wrap = document.querySelector('.proposal');
    let selectInputs = wrap.querySelectorAll('select:not(.ident)');
    let textInputs = wrap.querySelectorAll('input:not(.ident)');
    let textarea = wrap.querySelectorAll('textarea:not(.ident)');
    let allInputs = Array.from(selectInputs).concat(Array.from(textInputs)).concat(Array.from(textarea));

    let selectInputsLocked = wrap.querySelectorAll('select.ident');
    let textInputsLocked = wrap.querySelectorAll('input.ident');
    let textareaLocked = wrap.querySelectorAll('textarea.ident');
    let allInputsLocked = Array.from(selectInputsLocked).concat(Array.from(textInputsLocked)).concat(Array.from(textareaLocked));

    allInputsLocked.forEach(input => setLocked(input));
    allInputs.forEach(input => setEnabled(input));
    setEnabled(selector.submitBtn);
}

/**
 * uncheck both typ_parcely radio buttons
 */
function uncheckBothTypParcely() {
    selector.typParcely1.checked = false;
    selector.typParcely2.checked = false;
}

/**
 * facade for start a preloader
 */
export function startPreloader() {
    document.querySelector(".spinner").classList.remove("none");
}

/**
 * facade for stop a preloader
 */
export function stopPreloader() {
    document.querySelector(".spinner").classList.add("none");
}

/** 
 * initial field open of definition point and complaint description for 
 * ad-d, so-d, ul-d and other type specific type of form 
 * */
function initiateProposalSpecificTypeOfForms() {
    if (selector.submitBtn && !(stateManager.form_type === 'ul-dnu' || stateManager.form_type === 'ad-du')) {
        setEnabled(selector.submitBtn);
    }
    //for ulice input (case where ulice is in proposal section)
    if (selector.uliceProposal) {
        selector.uliceProposalAutocomplete.classList.add('none');
        // if autocomplete is needed for this boxes
        if (stateManager.form_type === 'ad-du' || stateManager.form_type === 'ad-d') {
            selector.uliceProposal.addEventListener('focus', () => {
                building.getUlice('.ulice_proposal', '.ulice_proposal_atc')
            })
        }
    }
    if (stateManager.form_type === 'ul-d') {
        if (selector.popisReklamace) {
            setEnabled(selector.popisReklamace);
            setEnabled(selector.caraUliceProposal);
        }
    }
    if (stateManager.form_type === 'so-d' || stateManager.form_type === 'ad-d') {
        if (selector.popisReklamace) {
            setEnabled(selector.popisReklamace);
            setEnabled(selector.defb_x_Proposal);
            setEnabled(selector.defb_y_Proposal);
            if (stateManager.form_type === 'ad-d') {
                setEnabled(selector.defb_x_hasici_Proposal);
                setEnabled(selector.defb_y_hasici_Proposal);
                setEnabled(selector.defb_y_zzs_Proposal);
                setEnabled(selector.defb_x_zzs_Proposal)
            }
        }
    }
    if (stateManager.form_type === 'so-dp') {
        setDisabled(selector.submitBtn);
        setDisabled(selector.parcelniCisloProposal);
        // handle katuze selectbox and behavior of typ_parcely inputs based on selected katuze
        selector.katUzemiProposal.addEventListener('input', (e) => {
            setEnabled(selector.typParcely1Proposal);
            setEnabled(selector.typParcely2Proposal);
            setDisabled(selector.parcelniCisloProposal);
            selector.parcelniCisloProposal.value = '';
            let katuze = e.target[e.target.selectedIndex].value;
            let rada = e.target[e.target.selectedIndex].getAttribute('data-cr');
            let typParcely;
            let radioProposal = document.querySelector('.radio_proposal');
            //check type of parcel numbering for each type of rada
            if (rada === '1') {
                fetch(DOMAIN_NAME + 'reklamaceRUIAN/getdruhcislovani' + '?katuze=' + katuze)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data === 2) {
                                selector.typParcely2Proposal.checked = true;
                                setDisabled(selector.typParcely1Proposal);
                                typParcely = 2;
                            }
                            if (data === 1) {
                                selector.typParcely1Proposal.checked = true;
                                setDisabled(selector.typParcely2Proposal);
                                typParcely = 1;
                            }
                            setEnabled(selector.parcelniCisloProposal);
                            building.getParcelniCisloProposal(typParcely, '.parcelni_cislo_proposal', '.parcelni_cislo_proposal_atc', katuze);
                        })
                        .catch((error) => console.error('There was a problem with the request: ' + error));
            } else {
                selector.typParcely2Proposal.checked = false;
                selector.typParcely1Proposal.checked = false;
                radioProposal.addEventListener('click', (e) => {
                    setEnabled(selector.parcelniCisloProposal);
                    typParcely = e.target.value;
                    selector.parcelniCisloProposal.value = '';
                    building.getParcelniCisloProposal(typParcely, '.parcelni_cislo_proposal', '.parcelni_cislo_proposal_atc', katuze);
                })
            }
            // control if user click outside of autocomplete selectbox (not choose any option) and reset the value in the cislo_popisne
            document.addEventListener('click', (e) => {
                let isClickInside = selector.parcelniCisloAutocompleteProposal.contains(e.target);
                if (!isClickInside && !selector.parcelniCisloAutocompleteProposal.classList.contains('none') &&
                        selector.parcelniCisloAutocompleteProposal.classList.contains('parcelni_cislo_proposal_atc')) {
                    selector.parcelniCisloProposal.value = '';
                    isProposalType(selector.parcelniCisloAutocompleteProposal, true);
                    selector.parcelniCisloAutocompleteProposal.classList.add('none');
                }
            });
        });
    }
    if (stateManager.form_type === 'so-dt') {
        if (selector.typSoProposal) {
            let textInput = selector.cisloPopisneProposal;
            console.log(textInput);
            selector.typSoProposal.addEventListener('input', (e) => {
                textInput.removeAttribute('data-nette-rules');
                if (selector.typSoProposal[selector.typSoProposal.selectedIndex].value == 1 || selector.typSoProposal[selector.typSoProposal.selectedIndex].value == 2) {
                    textInput.setAttribute('required', 'required');
                    textInput.setAttribute('data-nette-rules', '[{"op":":filled","msg":"Toto pole je povinné"},{"op":":maxLength","msg":"Povolený rozsah je maximálně 4 znaky","arg":4},{"op":":integer","msg":"Kód musí být číslo"}]');
                } else {
                    textInput.removeAttribute('required', null);
                    textInput.setAttribute('data-nette-rules', '[{"op":":maxLength","msg":"Povolený rozsah je maximálně 4 znaky","arg":4},{"op":":integer","msg":"Kód musí být číslo"}]');
                }
            });
            if (selector.typSoProposal.value == 3) {
                setDisabled(selector.castObceProposal);
                setDisabled(selector.cisloPopisneProposal);
            }
        }
    }
    // postupne otevirani formularu
//    if (stateManager.form_type === 'so-ta') {
////        setDisabled(selector.tea2);
//        selector.tea.addEventListener('input', (e) => {
//            let tea = e.target[e.target.selectedIndex];
//            if (tea.value == 1) {
//                setEnabled(selector.tea2);
//            } else {
//                setDisabled(selector.tea2);
//                selector.tea2.value = '';
//            }
//        });
//
//    }
}


// /**
//  * get data about the parcel number (based on autocomplete) and put them into the selectbox.
//  * its only for solving a proposal of parcel in so-dp type of form
//  */
// function getParcelniCisloProposal(typ_parcely, textInput, selectInput, katuze, typ_Parcely) {
//     let typParcely;
//     if (typ_parcely != undefined) {
//         typParcely = typ_parcely;
//     } else {
//         typParcely = 'undefined';
//     }

//     autocomplete(textInput, selectInput, 'getparcelnicislo', setPC.bind(this), '&katuze=' + katuze + '&typparcely=' + typParcely + '&form=' + stateManager.form_type)
//     // autocomplete('.parcelni_cislo', '.parcelni_cislo_atc', 'getparcelnicislo', setPC.bind(this), '&katuze=' + this.kat_uzemi + '&typparcely=' + typParcely + '&form=' + stateManager.form_type)
//     function setPC(cislo, kodRuian) {
//         fetch(DOMAIN_NAME + 'reklamaceRUIAN/getsobycode' + '?so_code=' + kodRuian)
//             .then((response) => response.json())
//             .then((data) => {
//                 selector.parcelaId.value = data.forObject.parcela_id;
//             })
//             .catch((error) => console.error('There was a problem with the request: ' + error));
//     }
// }