// eslint-disable-next-line no-undef
document.addEventListener('DOMContentLoaded', () => naja.initialize());

import "../scss/main.scss";
import Identification from "./identification-module.js";
import { transformation } from "./gps-transformation-module.js";
import { startIdentification, stopPreloader } from "./form-manager.js";
import { selector } from "./selectors-module.js";

export const DOMAIN_NAME = "/formular/";

// Initialzation of identification part of form   
export const building = new Identification();
startIdentification();
transformation();

//for situation when navigation is controled via burger icon (to toggle navigation menu)
selector.burger.addEventListener('click', () => {
    //toggle nav
    selector.main_nav_links.classList.toggle('nav-active');
    //animate links
    selector.main_nav_links_li.forEach((link, index) => {
        if (link.style.animation) {
            link.style.animation = ''
        } else {
            link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.7}s`;
        }
    });
    //burger animation
    selector.burger.classList.toggle('toggle');
});

