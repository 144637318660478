import { DOMAIN_NAME } from "./main.js";

export function transformation() {

    let errorMessage =
        `Vložte souřadnice v podporovaném formátu z území České republiky<br>
        <li>50.1263414N, 14.6709333E</li>
        <li>50.1263414,14.6709333</li>
        <li>50.1263414 14.6709333</li>`;
    let callFrom = null;

    // SELECTORS 
    const gpsOpenBtns = document.querySelectorAll(".gps-box-btn");
    const gpsTransformBtn = document.querySelector(".gps_transform_btn");
    const transformationBox = document.querySelector(".modal");
    const gpsMsgBox = document.querySelector(".gps-msgBox");
    const gpsCloseBtn = document.querySelector(".gps-closeBtn");
    const gpsInput = document.querySelector("#gps");
    const xInput = document.querySelector("#x_tmp");
    const yInput = document.querySelector("#y_tmp");
    const insertBtn = document.querySelector(".btn-insert");

    gpsOpenBtns?.forEach(gpsOpenBtn => {
        gpsOpenBtn.addEventListener("click", (e) => {
            e.preventDefault;
            callFrom = e.target.parentElement;
            transformationBox.classList.add('modal-active');
            gpsMsgBox.innerText = "";
            gpsInput.value = "";
            xInput.value = "";
            yInput.value = "";
        });
    });

    gpsTransformBtn?.addEventListener("click", () => {
        checkTransformation();
    });

    gpsCloseBtn?.addEventListener("click", () => {
        transformationBox.classList.remove('modal-active');
    });

    insertBtn?.addEventListener("click", (e) => {
        e.preventDefault;
        saveResults();
    });

    function validateGPS(input) {
        let gps = input.toUpperCase().trim().replace("N", "").replace("E", "").replace(";", ",").replace(",", " ").replace("  ", " ").split(" ");
        if (gps.length !== 2) {
            return "CHYBA FORMÁTU";
        } else {
            let x = new RegExp('((48|49|50|51)(.d+)?)');
            let y = new RegExp('((12|13|14|15|16|17|18)(.d+)?)');
            if (x.test(gps[0]) && y.test(gps[1])) {
                return gps;
            } else {
                return "CHYBA SOUŘADNIC";
            }
        }
    }

    function transform(x, y) {
        fetch(DOMAIN_NAME + 'reklamaceRUIAN/' + "transform?x=" + x + "&y=" + y)
            .then((response) => response.json())
            .then((data) => {
                let result = data.split(";");
                if (result instanceof Array && result.length === 2) {
                    gpsMsgBox.innerHTML = "<b>Transformace proběhla úspěšně</b>";
                    gpsMsgBox.classList.add('gps-success');
                    yInput.value = Math.round(result[1] * 100) / 100;
                    xInput.value = Math.round(result[0] * 100) / 100;
                } else {
                    gpsMsgBox.innerHTML = "<b>CHYBA TRANSFORMACE</b><br>" + errorMessage;
                    gpsMsgBox.classList.add('gps-error');
                }
            })
            .catch((error) => console.error('There was a problem with the request: ' + error));
    }

    function checkTransformation() {
        xInput.value = "";
        yInput.value = "";
        gpsMsgBox.innerHTML = "Probíhá transformace...";
        let validation = validateGPS(gpsInput.value);
        if (validation instanceof Array) {
            // if validation of input is ok
            transform(validation[0], validation[1]);
        } else {
            gpsMsgBox.innerHTML = "<b>" + validation + "</b><br>" + errorMessage;
            gpsMsgBox.classList.add('gps-error');
        }
    }

    function saveResults() {
        if (xInput.value && yInput.value) {
            let formYInput = callFrom.querySelectorAll('input')[0];
            let formXInput = callFrom.querySelectorAll('input')[1];
            formXInput.value = xInput.value;
            formYInput.value = yInput.value;
            transformationBox.classList.remove('modal-active');
        }
    }

}

